.loading-spinner {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 24;
  padding: 4rem;
  .spinner {
    position: relative;
    top: 40%;
    text-align: center;
    & > div:first-child {
      margin: 0 auto;
    }
    span {
      display: inline-block;
      font-weight: 600;
      margin-top: 1rem;
      font-size: 90%;
      color: #222;
    }
  }
}
