@import "../../../styles/responsive-utils.module.scss";
@import "../../../../common.scss";

.social-media-container {
  position: fixed;
  right: 0;
  left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 110;
  top: 30%;
  direction: ltr;

  @extend %web-view;

  i {
    transition: all 0.3s ease-in-out;
    width: 4rem;
    height: 4rem;
    text-align: center;
    background: #bbbbbb;
    padding-top: 1rem;
    color: #fff;
    font-size: 200%;
    margin: 0.07rem 0;
    &:hover {
      width: 5rem;
    }
  }
  i.facebook {
    background: #4267b2;
  }
  i.linkedin {
    background: #0175b2;
  }
  i.instagram {
    background: palevioletred;
  }
  i.twitter {
    background: #000;
  }
  i.youtube {
    background: #ef4957;
  }
}

.social-media-container-RTL {
  left: 0;
  right: auto;
  align-items: flex-start;
}
@media only screen and (max-width: $phone_max_width) {
  .social-media-container {
    i {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 150%;
      margin: 0.1rem 0;
      &:hover {
        width: 4rem;
      }
    }
  }
}
