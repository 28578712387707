
@import '../../common.scss';

%web-view {
    @media only screen and (max-width: $tablet_max_width) {
        &{
            display: none !important;
        }
    }
}

%web-tablet-view {
    @media only screen and (max-width: $phone_max_width) {
        &{
            display: none !important;
        }
    }
}

%mobile-view {
    @media only screen and (min-width: $phone_max_width) {
        &{
            display: none !important;
        }
    }
}
