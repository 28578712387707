:root {
  --main-color: #02738c;
  --main-color-transparent: #02738c;
  --secondary-color:#fff;
  --gray: #d6d6d6;
  --light-gray: #f1f1f1;
  --dark-gray: #575757;
  --shadow: 0 7px 35px 0 rgba(24, 24, 24, 0.1);
}

* {
  margin: 0;
  padding: 0;
  word-break: break-word;
  box-sizing: border-box;
  font-family: Cairo, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
html {
  font-size: 16px;
  height: 100vh;
}
.hidden-label {
  overflow: hidden;
  text-indent: -200px;
}
body {
  min-height: 100vh;
  margin: 0;
  font-family: Cairo, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  overflow-wrap: break-word;
}
#root {
  min-height: 100vh;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-v-center {
  align-items: center;
}
.flex-h-center {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-space-between {
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 10px;
  }
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 30px;
  background: #eee !important;
  color: #222 !important;
}
.leaflet-container {
  z-index: 0 !important;
}
.leaflet-control-geosearch a.reset {
  right: unset !important;
  left: 0;
}
.leaflet-touch .leaflet-geosearch-bar form input {
  text-align: right !important;
}
.-paginate-rtl .-pagination {
  direction: rtl;
}

@media screen and (max-width: 1024px) {
  .notification-container-mobile-bottom {
    margin-bottom: 10vh !important;
  }
}
