@import '../../../common.scss';


.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h2 {
        font-size: 2.3rem;
    }
    h1 {
        border-bottom: 5px solid var(--main-color);
    }

    img {
        width: 22rem;
    }
}