.wrapper {
  font-size: 2rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  padding: 3rem;
  /* background-image: url("/images/trans-logo.png"); */
  background-repeat: no-repeat;
  background-position: right bottom;
  padding-top: 7rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.03);
}
.wrapper h1 {
  font-size: 160%;
}
.wrapper .mof-logo {
  width: 200px;
  height: 200px;
}
.subheading {
  font-size: 1.4rem;
  font-weight: lighter;
  font-family: "Tahoma";
}
.browsersContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  padding: 4rem;
  border-radius: 40px;
}
.browser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}
.browserImage {
  width: 70px;
  height: 70px;
  margin-bottom: 2rem;
}
.browserName {
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: lighter;
  font-size: 1.5rem;
}
@media only screen and (max-width: 460px) {
  .wrapper {
    padding: 4rem;
  }
  .wrapper h1 {
    font-size: 120%;
  }
  .wrapper .mof-logo {
    width: 150px;
    height: 150px;
  }
  .browsersContainer {
    flex-direction: column;
    padding: 4rem;
  }
  .browser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
  }
}
