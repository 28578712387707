.container {
    align-items: baseline;
    background: #02738C none repeat scroll 0% 0%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;

    div {
        flex: none 0 300px;
        margin: 15px;
    }

    button {
        background: #F7F7F7 none repeat scroll 0% 0%;
        border: 0px none;
        border-radius: 3px;
        box-shadow: none;
        color: black;
        cursor: pointer;
        flex: 0 0 auto;
        padding: 5px 10px;
    }
}